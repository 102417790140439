import { graphql, useStaticQuery } from 'gatsby';

const useJAIntermentCamps = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { series: { eq: "Japanese-American Internment Camps" } }
        }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        nodes {
          frontmatter {
            name
            slug
            title
            series
            media
            size
            year
            city
            state
            country
          }
        }
      }
    }
  `);

  return data.allMdx.nodes;
};

export default useJAIntermentCamps;
