import { graphql, useStaticQuery } from 'gatsby';

const useSacredArchitecturesCambodia = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          frontmatter: {
            country: { eq: "Cambodia" }
            series: { eq: "Sacred Architectures" }
          }
        }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        nodes {
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            name
            slug
            title
            series
            media
            size
            year
            city
            state
            country
          }
        }
      }
    }
  `);

  // console.log(data);
  data.allMdx.nodes.filter((art) => art.series == 'Sacred Architectures');
  // console.log(data);
  return data.allMdx.nodes;
};

export default useSacredArchitecturesCambodia;
