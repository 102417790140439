import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavLink from './navlink';
import './accordion-navlist.css';

import useSacredArchitecturesCambodia from '../hooks/use-sacred-architectures-cambodia';
import useSacredArchitecturesIndia from '../hooks/use-sacred-architectures-india';
import useSacredArchitecturesJapan from '../hooks/use-sacred-architectures-japan';
import useSacredArchitecturesNepal from '../hooks/use-sacred-architectures-nepal';
import useSacredArchitecturesThailand from '../hooks/use-sacred-architectures-thailand';
import useAbandonedPrisons from '../hooks/use-abandoned-prisons';
import useCityWorks from '../hooks/use-city-works';
import useCommissions from '../hooks/use-commissions';
import useEPASuperfundSites from '../hooks/use-epa-superfund-sites';
import useInterneePortraits from '../hooks/use-internee-portraits';
import useIntermentCamps from '../hooks/use-internment-camps';
import useJAIntermentCamps from '../hooks/use-ja-internment-camps';
import useJCIntermentCamps from '../hooks/use-jc-internment-camps';
import useMiscellaneous from '../hooks/use-miscellaneous';
import usePostIndustrialLandscapes from '../hooks/use-post-industrial-landscapes';
import useWarAndMilitary from '../hooks/use-war-and-military';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: '#222',
  },
  countryHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.6rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8em',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
    color: theme.palette.secondary,
    paddingLeft: '1rem',
  },
  sacredCategoryHeading: {
    paddingLeft: '.5rem',
    paddingTop: '.5rem',
  },
  categoryHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },

    color: theme.palette.secondary,
    paddingLeft: '.5rem',
    width: '100%',
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panel: {
    background: '#222',
  },
  panelCategory: {},
  panelDetails: {
    background: '#222',
    padding: '0',
    marginLeft: '2rem',
    paddingBlockEnd: '20px',
  },
  panelSummary: {
    color: '#ddd',
    fill: '#ddd',
    paddingLeft: '1rem',
    '& svg': {
      fill: 'white',
    },
  },
  panelSummaryCategory: {
    color: '#ddd',
    fill: '#ddd',
    paddingLeft: '0px',
    '& svg': {
      fill: 'white',
    },
  },
  ul: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
  },
}));

export default function AccordionNavlist() {
  const classes = useStyles();

  const sacredArchitecturesCambodia = useSacredArchitecturesCambodia();
  let cambodiaSlugs = sacredArchitecturesCambodia.map(
    (a) => a.frontmatter.slug
  );
  const sacredArchitecturesIndia = useSacredArchitecturesIndia();
  let indiaSlugs = sacredArchitecturesIndia.map((a) => a.frontmatter.slug);
  const sacredArchitecturesJapan = useSacredArchitecturesJapan();
  let japanSlugs = sacredArchitecturesJapan.map((a) => a.frontmatter.slug);
  const sacredArchitecturesThailand = useSacredArchitecturesThailand();
  let thailandSlugs = sacredArchitecturesThailand.map(
    (a) => a.frontmatter.slug
  );
  const sacredArchitecturesNepal = useSacredArchitecturesNepal();
  let nepalSlugs = sacredArchitecturesNepal.map((a) => a.frontmatter.slug);

  const abandonedPrisons = useAbandonedPrisons();
  const cityWorks = useCityWorks();
  const commissions = useCommissions();
  const epaSuperfundSites = useEPASuperfundSites();
  const interneePortraits = useInterneePortraits();
  const internmentCamps = useIntermentCamps();
  const jAInternmentCamps = useJAIntermentCamps();
  const jCInternmentCamps = useJCIntermentCamps();
  const miscellaneous = useMiscellaneous();
  const postIndustrialLandscape = usePostIndustrialLandscapes();
  const warAndMiliary = useWarAndMilitary();

  const artLinksCambodia = sacredArchitecturesCambodia.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink
          to={`/${art.frontmatter.slug}`}
          state={{
            series: art.frontmatter.series,
            country: art.frontmatter.country,
          }}
          tabIndex="0"
        >
          {art.frontmatter.title}, {art.frontmatter.year}©
        </NavLink>
      </em>
    </li>
  ));
  const artLinksIndia = sacredArchitecturesIndia.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink
          to={`/${art.frontmatter.slug}`}
          state={{
            series: 'Sacred Architectures',
            country: 'India',
          }}
        >
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksJapan = sacredArchitecturesJapan.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksNepal = sacredArchitecturesNepal.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink
          to={`/${art.frontmatter.slug}`}
          state={{
            series: 'Sacred Architectures',
            country: 'Nepal',
          }}
        >
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksThailand = sacredArchitecturesThailand.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink
          to={`/${art.frontmatter.slug}`}
          state={{
            series: 'Sacred Architectures',
            country: 'Thailand',
          }}
        >
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksAbandonedPrisons = abandonedPrisons.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink
          to={`/${art.frontmatter.slug}`}
          state={{
            series: 'Abandoned Prisons',
          }}
        >
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksCityWorks = cityWorks.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksCommissions = commissions.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksEPASuperfundSites = epaSuperfundSites.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksInterneePortraits = interneePortraits.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksJAInternmentCamps = jAInternmentCamps.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksJCInternmentCamps = jCInternmentCamps.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksMiscellaneous = miscellaneous.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksPostIndustrialLandscape = postIndustrialLandscape.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));
  const artLinksWarAndMiliary = warAndMiliary.map((art) => (
    <li key={art.frontmatter.name}>
      <em>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </em>
    </li>
  ));

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    event.preventDefault();

    setExpanded(isExpanded ? panel : false);
  };

  return (
    <nav className={classes.root}>
      <Typography
        variant="h3"
        className={classes.sacredCategoryHeading}
        color="secondary"
      >
        Sacred Architectures
      </Typography>

      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.panelSummary}
        >
          <Typography
            className={classes.countryHeading}
            variant="h4"
            color="secondary"
          >
            Cambodia
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography>{artLinksCambodia}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={classes.panelSummary}
        >
          <Typography
            className={classes.countryHeading}
            variant="h4"
            color="secondary"
          >
            India
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography>{artLinksIndia}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className={classes.panelSummary}
        >
          <Typography
            className={classes.countryHeading}
            variant="h4"
            color="secondary"
          >
            Japan
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography>{artLinksJapan}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          className={classes.panelSummary}
        >
          <Typography
            className={classes.countryHeading}
            variant="h4"
            color="secondary"
          >
            Nepal
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography>{artLinksNepal}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          className={classes.panelSummary}
        >
          <Typography
            className={classes.countryHeading}
            variant="h4"
            color="secondary"
          >
            Thailand
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography>{artLinksThailand}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Abandoned Prisons
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksAbandonedPrisons}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            City Works
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksCityWorks}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Commissions
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksCommissions}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            EPA Superfund Sites
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksEPASuperfundSites}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel10'}
        onChange={handleChange('panel10')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Internment Survivor Portraits
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksInterneePortraits}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel11'}
        onChange={handleChange('panel11')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Japanese-American Internment Camps
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksJAInternmentCamps}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel12'}
        onChange={handleChange('panel12')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Japanese-Canadian Internment Camps
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksJCInternmentCamps}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel13'}
        onChange={handleChange('panel13')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Miscellaneous
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksMiscellaneous}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel14'}
        onChange={handleChange('panel14')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            Post Industrial Landscape
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksPostIndustrialLandscape}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel15'}
        onChange={handleChange('panel15')}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
          className={classes.panelSummaryCategory}
        >
          <Typography
            className={classes.categoryHeading}
            variant="h3"
            color="secondary"
          >
            War and Military
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <ul className={classes.ul}>
            <Typography> {artLinksWarAndMiliary}</Typography>
          </ul>
        </AccordionDetails>
      </Accordion>
    </nav>
  );
}
